<template>
  <div class="gestioncaso">
    <div class="flex flex-col md:flex-row">
      <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-12 pb-24 md:pb-5">
        <div class="bg-gray-800 pt-3">
          <div
            class="
              rounded-tl-3xl
              bg-gradient-to-r
              from-blue-900
              to-gray-800
              p-4
              shadow
              text-2xl text-white
            "
          >
            <h3 class="font-bold pl-2 font-sans">Gestión del Caso</h3>
          </div>
        </div>

        <div class="flex flex-wrap mt-2">
          <div class="w-full p-6">
            <div class="requestViewDetails requestViewDetails6">
              <div class="detailBox">
                <div class="itemTitle">Estado</div>
                <div class="itemCont" title="Estado">
                  {{ caso.descripcionEstadoReclamos }}
                </div>
              </div>

              <div class="detailBox">
                <div class="itemTitle">Prioridad</div>
                <div class="itemCont" title="Prioridad">
                  {{ caso.descripcionPrioridades }}
                </div>
              </div>

              <div class="detailBox">
                <div class="itemTitle">Tipo</div>
                <div class="itemCont" title="tipo">
                  {{ caso.descripcionTipoReclamo }}
                </div>
              </div>

              <div class="detailBox">
                <div class="itemTitle">Zona</div>
                <div class="itemCont" title="zona">{{ caso.codigoZona }}</div>
              </div>

              <!-- <div class="detailBox" title="fecha">
                <div class="itemTitle">Fecha</div>
                <div class="itemCont">{{ caso.fechaIngreso }}</div>
              </div> -->
              <div class="detailBox" title="reasignar">
                <label for="nombre" class="text-gray-500 text-xs">Reasignar</label
                >
                <div
                  v-if="caso.descripcionEstadoReclamos != 'CERRADO'"
                  class=""
                >
                  <v-select
                    :options="this.usuariosProceso"
                    label="nombre"
                    :reduce="(user) => user.id"
                    v-model="usuarioAsignado"
                    class="
                      w-32
                      lg:w-40
                      xl:w-72
                      inline-block
                      mr-3
                      bg-gray-200
                      text-black text-xs
                      rounded-lg
                    "
                  ></v-select>

                  <!-- <select
                    class="w-auto inline-block bg-gray-200 border border-gray-200 text-black text-xs rounded"
                    id="location"
                    ><option>--Seleccione--</option>
                    <option
                      v-for="usuario of usuariosProceso"
                      :key="usuario.id"
                      :value="usuario.id"
                      >{{ usuario.nombre }}</option #14662
                    > </select
                  >-->
                  &nbsp;
                  <button @click="reasignar()" class="buttonBlue">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
              <div
                class="grid grid-cols-1 inline"
                v-if="caso.descripcionEstadoReclamos != 'CERRADO'"
              >
                <label for="nombre" class="text-gray-500 text-xs"
                  >Cambio de area</label
                >
                <div>
                  <v-select
                    :options="this.tiposDeCaso"
                    label="descripcionProceso"
                    :reduce="(data) => data.idusuario"
                    id="reasignarPorArea"
                    v-model="reasignarPorAreaV"
                    class="
                      w-32
                      lg:w-40
                      xl:w-72
                      inline-block
                      mr-3
                      bg-gray-200
                      text-black text-xs
                      rounded-lg
                    "
                  ></v-select>
                  <button class="mx-2 buttonBlue" @click="reasignarPorArea()">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>

              <div class="detailBox" title="cerrar">
                <div class="itemTitle">Cerrar caso</div>
                <div class="itemCont">
                  <input
                    v-if="caso.descripcionEstadoReclamos != 'CERRADO'"
                    v-model="checked"
                    @change="cerrarCaso()"
                    type="checkbox"
                  />
                </div>
              </div>
              <a href="#openModal-about">
                <button
                  v-if="botton && caso.descripcionEstadoReclamos == 'CERRADO'"
                  class="
                    mx-2
                    mt-2
                    p-1
                    w-10
                    h-10
                    rounded
                    font-bold
                    bg-gradient-to-r
                    from-blue-900
                    to-blue-500
                    text-white text-xl
                  "
                  @click="cambio()"
                >
                  <i class="far fa-file-alt"></i>
                </button>
              </a>
              <div class="requestViewId">#{{ caso.numcodigo }}</div>
            </div>
            <div id="synthomAndForm">
              <div class="messageContainer messageContainerFirst">
                <div class="userData">
                  <div class="userImage">
                    <a href="#"
                      ><img
                        src="https://www.clipartmax.com/png/small/256-2564545_nauman-javid-none-profile.png"
                        alt=""
                    /></a>
                  </div>
                </div>

                <div class="messageData">
                  <a name="0"></a>
                  <div class="messageArrow"></div>
                  <div class="messageTop">
                    <div class="userName">
                      <a
                        class="userNameIcon"
                        onclick="$(this).popUp({ targetAjax: '/user/miniprofile/index/user_id/12', topOffset:10 })"
                        href="javascript:;"
                        >{{ caso.nombreUsuarioCreacion }}</a
                      >
                    </div>
                    <div class="userPosition">{{ caso.usuarioCreacion }}</div>
                    <div style="flex-grow: 1"></div>
                    <div class="messageDate">
                      Publicado:
                      <span
                        data-timestamp="1429545840"
                        data-timestamp-type="0"
                        time-relative="6 years ago"
                        >{{
                          caso.fecha_Ingreso
                            | moment(
                              "timezone",
                              "America/Bogota",
                              "Do MMMM YYYY, h:mm a"
                            )
                        }}</span
                      >
                    </div>
                    <div class="messageNumber">
                      <span class="number synthom">Descripción</span>
                    </div>
                  </div>

                  <div class="messageCont text-left">
                    <p>
                      {{ caso.descripcionReclamo }}
                    </p>
                    <div class="clear"></div>
                  </div>

                  <!-- ATTACHED FILES -->

                  <div
                    v-if="caso.imagenUrl != null && caso.imagenUrl != ''"
                    class="messageAttachments"
                  >
                    <div class="filesAttached">
                      <div
                        v-if="caso.imagenUrl != null"
                        class="item"
                        style="overflow: hidden;"
                      >
                        <a
                          :id="'href_'+caso.numcodigo"
                          href=""
                        >
                          <div class="filename">
                            {{ caso.imagenUrl }}
                          </div>
                        </a>
                        <div class="fileField">
                          <img :id="'img_'+caso.numcodigo" class="zoomable cursor-pointer" src="" alt="" @click="openModalImg('img_'+caso.numcodigo)">
                        </div>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <!-- END ATTACHED FILES -->
                </div>
                <div class="clear"></div>
              </div>

              <div
                id="responseForm"
                data-token="6235872ffba2360e742f46b218a3aee5"
                data-uploadurl="/upload/manager/response"
              >
                <div class="replyBar" style="margin: 20px">
                  <div class="replyBarType">
                    <a id="switch_solution" style="display: none">Solution</a>

                    <div class="clear"></div>
                  </div>

                  <div id="replyingTo"></div>

                  <div class="clear"></div>

                  <div
                    id="form_reply"
                    v-if="caso.descripcionEstadoReclamos != 'CERRADO'"
                  >
                    <input name="id" type="hidden" value="347" />
                    <input
                      name="token"
                      type="hidden"
                      value="6235872ffba2360e742f46b218a3aee5"
                    />
                    <input
                      id="reference"
                      type="hidden"
                      value="0"
                      name="reference"
                    />
                    <input
                      id="issolution"
                      type="hidden"
                      value="0"
                      name="is_solution"
                    />
                    <input
                      type="hidden"
                      name="update_timestamp"
                      value="1621343635"
                    />
                    <input
                      id="reply_type"
                      type="hidden"
                      value="reply_only"
                      name="reply_type"
                    />
                    <input
                      id="reply_type_params"
                      type="hidden"
                      value=""
                      name="reply_type_params"
                    />
                    <div class="flex inline text-white">
                      <textarea
                        v-model="descripcionComentario"
                        name="message"
                        style="margin-bottom: 8px"
                        id="reply_textarea"
                        class="text-input validate[required]"
                        data-default="Enter your reply here..."
                        aria-label="Enter your reply here"
                        placeholder="Ingrese su comentario aquí..."
                      ></textarea>
                      <a href="#openModal-about">
                        <button
                          v-if="botton"
                          class="
                            mx-2
                            mt-2
                            p-1
                            w-10
                            h-10
                            rounded-lg
                            font-bold
                            bg-blue-900
                            text-white text-xl
                          "
                          @click="cambio()"
                        >
                          <i class="far fa-file-alt"></i>
                        </button>
                      </a>
                    </div>
                    <div class="uploadPasteContainer">
                      <div class="uploadContainer upload_container"></div>
                    </div>
                    <div class="clear"></div>
                      <div class=" relative block mx-24" id="attachFile">
                        <UploadImages
                          clearAll="Borrar todo"
                          uploadMsg="Click para subir una imágen o suéltala aquí"
                          @change="handleImages"
                          maxError="Solo puede cargar una imagen"
                          :max="2"
                        />
                      </div>
                      <div class="replyButtons">
                        <div style="float: right">
                          <button v-if="!guardando" @click="guardarComentario()" class="buttonBlue inline-block">
                            <i class="fa fa-save text-white pr-2 "
                            ></i
                            ><label exact :style="{ cursor: 'pointer' }" class="font-sans font-medium text-lg">
                              &nbsp;Responder</label>
                              </button>
                          <button v-else class="buttonGray inline-block">
                            <img class=" relative block h-full mx-auto" src="https://gestioncasos.juanabonita.com.co/img/cargandoJB.gif" alt="">
                          </button>
                        </div>
                    </div>

                    <div
                      class="linkedSolutions dropContainer ui-sortable"
                      id="articles_to_link"
                    ></div>
                    <div class="clear"></div>
                  </div>
                  <div class="clear"></div>
                  <div
                    :key="detalle.numcodigo"
                    v-for="(detalle, index) of detalleReclamo"
                    class="p-6 border-t m-5"
                  >
                    <div
                      id="message_container_reply_4"
                      class="messageContainer messageReply containerImpair"
                    >
                      <div class="userData">
                        <div class="userImage">
                          <a href="#"
                            ><img
                              src="https://www.clipartmax.com/png/small/256-2564545_nauman-javid-none-profile.png"
                              alt=""
                          /></a>
                        </div>
                      </div>

                      <div class="messageData">
                        <span id="reply_1"></span>

                        <div class="messageArrow"></div>
                        <div class="messageTop">
                          <div class="userName">
                            <a
                              class="userNameIcon"
                              onclick="$(this).popUp({ targetAjax: '/user/miniprofile/index/user_id/3', topOffset:10 })"
                              href="javascript:;"
                              >{{ detalle.nombreUsuario }}</a
                            >
                          </div>
                          <div class="userPosition">
                            {{ detalle.nombreLogin }}
                          </div>
                          <div style="flex-grow: 1"></div>
                          <div class="messageDate">
                            <span
                              data-timestamp="1429545991"
                              data-timestamp-group="history"
                              time-absolute="Apr 20 2015, 16:06"
                              time-relative="6 years ago"
                              title="6 years ago"
                              >{{
                                detalle.fecha
                                  | moment(
                                    "timezone",
                                    "America/Bogota",
                                    "Do MMMM YYYY, h:mm a"
                                  )
                              }}</span
                            >
                          </div>
                          <div class="messageNumber"># {{ index + 1 }}</div>
                        </div>

                        <div
                          class="messageCont text-left"
                          id="message_cont_1188"
                        >
                          <p>{{ detalle.descripcionReclamo }}&nbsp;</p>
                        </div>

                        <!-- ATTACHED FILES -->

                        <div
                          v-if="detalle.imagenUrl != null && detalle.imagenUrl != ''"
                          class="messageAttachments"
                        >
                          <div class="filesAttached">
                            <div
                              class="item"
                              style="overflow: hidden;"
                            >
                              <a
                                :id="'href_'+detalle.numcodigo"
                                href=""
                                data-gallery="request"
                                data-gallery-index="1"
                              >
                                <div class="filename">
                                  {{ detalle.imagenUrl }}
                                </div>
                              </a>
                              <div class="fileField">
                                <img :id="'img_'+detalle.numcodigo" class="zoomable cursor-pointer" src="" alt="" @click="openModalImg('img_'+detalle.numcodigo)">
                              </div>
                            </div>
                            <div class="clear"></div>
                          </div>
                        </div>
                        <!-- END ATTACHED FILES -->
                      </div>
                      <div class="clear"></div>
                      <div class="messageWho messageWhoEmpty"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--modals-->
        <div v-show="datos" id="openModal-about" class="modalDialog">
          <div>
            <h1
              class="
                bg-gradient-to-r
                from-blue-900
                to-gray-800
                text-white text-center
                rounded-lg
              "
            >
              Cambios y Devoluciones
            </h1>
            <a href="#close" title="Close" class="close">
              <button @click="cerrar()">X</button>
            </a>
            <div class="bg-blue-100">
              <div>
                <h1>
                  <div class="shadow border-b border-gray-200 rounded-lg">
                    <table
                      id="Topicos_Datatable"
                      class="
                        ListaTabla
                        header_fijo
                        min-w-full
                        uppercase
                        bg-white
                        mt-1
                      "
                    >
                      <div
                        class="
                          grid grid-cols-9
                          bg-blue-800
                          text-white text-center
                        "
                      >
                        <th
                          class="text-left pl-4 uppercase font-semibold text-sm"
                        >
                          Factura
                        </th>
                        <th
                          class="
                            text-center
                            px-1
                            uppercase
                            font-semibold
                            text-sm
                          "
                        >
                          Campaña
                        </th>
                        <th
                          class="
                            text-center
                            px-1
                            uppercase
                            font-semibold
                            text-sm
                          "
                        >
                          Fecha
                        </th>
                        <th class="px-4 uppercase font-semibold text-sm">
                          Codigo
                        </th>
                        <th
                          class="
                            px-1
                            uppercase
                            font-semibold
                            text-sm
                            col-span-1
                          "
                        >
                          Entrada-salida
                        </th>
                        <th
                          class="
                            px-1
                            uppercase
                            font-semibold
                            text-sm text-center
                            col-span-2
                          "
                        >
                          Causal
                        </th>

                        <th class="px-4 uppercase font-semibold text-sm">
                          Cantidad
                        </th>

                        <th class="px-4 uppercase font-semibold text-sm">
                          Tipo C o D
                        </th>
                      </div>
                      <tbody class="text-gray-700 divide-y-2 border rounded-lg">
                        <tr
                          class="
                            bg-gray-100
                            grid grid-cols-9
                            border border-gray-200
                            hover:bg-white
                            divide divide-x divide-gray-200
                          "
                          :key="data.numcodigo"
                          v-for="data in camdev"
                        >
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold '
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                            class="text-left px-4"
                          >
                            {{ data.numeroFactura }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                            class="text-left px-4"
                          >
                            {{ data.campaña }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold '
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                          >
                            {{ data.fecha.substring(0, 10) }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                          >
                            {{ data.codigoproducto }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                          >
                            {{ data.tipo }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                            class="col-span-2"
                          >
                            {{ data.descripcionCausal }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400  text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                          >
                            {{ data.cantidad }}
                          </td>
                          <td
                            :class="
                              data.tipo == 'S'
                                ? 'bg-red-300 hover:bg-red-400 text-black font-bold'
                                : 'bg-gray-200 hover:bg-white font-bold'
                            "
                          >
                            {{ data.tipoCambioDev }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalImg" class="modalImg" @click="closeModalImg()">
      <img id="ImgFull" src="" alt="">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import auth from "@/logic/auth";
import UploadImages from "vue-upload-drop-images";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
//const moment = require("moment");
require("moment/locale/es");

// Vue.use(require("vue-moment"), { moment });
Vue.use(VueMoment, {
  moment,
});

export default {
  data() {
    return {
      caso: [],
      usuariosProceso: [],
      detalleReclamo: [],
      checked: false,
      comentario: "",
      descripcionComentario: "",
      idreclamo: "",
      ListImagenes: {},
      fileGrabar: "",
      fileGrabarExt: "",
      base64Aux: "",
      usuarioAsignado: "",
      idTipoReclamo: "",
      datos: false,
      camdev: [],
      botton: false,
      emailUsuCreacion: "",
      tiposDeCaso: [],
      reasignarPorAreaV: "",
      showModalImg: false,
      subeImg: false,
      guardando: false,
    };
  },

  name: "GestionCaso",
  components: { UploadImages },
  mounted() {
    var user = auth.getUserLogged();
    user = JSON.parse(user);
    axios
      .get(
        this.$hostname +
          "/Api/Detalle_Cambios_Devoluciones/GetDetalleCambiosDevSp/" +
          this.idreclamo,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        var data = response.data.length;
        //console.log(response.data);
        //console.log(data);
        setTimeout((response) => {
          if (data == 0) {
            this.botton = false;
          } else {
            this.botton = true;
          }
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    openModalImg(id){
      this.showModalImg = true
      setTimeout((() => {
        var src = document.getElementById(id).getAttribute("src")
        var item = document.getElementById("ImgFull")
        item.setAttribute("src",src)
        //console.log(item)
      }), 300)
    },
    closeModalImg(){
      this.showModalImg = false
    },
    cambio() {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(
          this.$hostname +
            "/Api/Detalle_Cambios_Devoluciones/GetDetalleCambiosDevSp/" +
            this.idreclamo,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          var data = response.data.length;
          this.camdev = response.data;

          setTimeout((response) => {
            if (data == 0) {
              this.datos = false;
            } else {
              this.datos = true;
            }
          }, 500);
        })
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    },
    ...mapMutations([
      "loadNavbar", 
      "hiddenNavbar", 
      "logueo", 
      "openNavCasos", 
      "openModalCargando",
      "closeModalCargando"
    ]),
    handleImages(files) {
      if(files.length > 0){
        var file = files[0]
        var reader = new FileReader();
        reader.onload = () => {
          this.base64Aux = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          this.fileGrabar = files[0];
          this.fileGrabarExt = this.fileGrabar.name.split(".")
          this.subeImg = true
          //console.log(
          //  {
          //    "id": "no se sabe",
          //    "idDetalle": "no se sabe",
          //    "nombreArchivo": "imgRespuesta." + this.fileGrabarExt[this.fileGrabarExt.length-1],
          //    "base64": this.base64Aux
          //  },
          //)
          //console.log(this.base64Aux)
          //console.log(this.fileGrabar)
        }
        reader.readAsDataURL(file);
      }else{
        this.subeImg = false
      }
    },
    cerrar() {
      this.datos = false;
    },
    cerrarCaso() {
      this.$confirm(
        "¿Estás seguro que deseas cerrar este caso?",
        "Cerrar Caso",
        "question"
      )
        .then(() => {
          var user = auth.getUserLogged();
          user = JSON.parse(user);
          axios
            .get(
              this.$hostname +
                "/Api/Reclamos/CerrarReclamoSp/" +
                this.idreclamo +
                "/" +
                this.emailUsuCreacion,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then((res) => {})
            .catch((err) => {
              console.log(err)
              this.$alert(
                "Ocurrió un error durante el proceso! Comunicate con el Area de Sistemas (Ext. 150)",
                "Error",
                "error"
              ).then({});
            });
          this.$router.push("/Casos/MisCasos");
        })
        .catch(() => {
          this.checked = false;
        });
    },
    guardarComentario() {
      this.guardando = true
      let idReclamo = this.idreclamo
      if(this.descripcionComentario.length <= 5){
        this.$alert(
            "Debes ingresar una respuesta completa y explicita para el caso!",
            "Error",
            "error"
        ).then({});
        this.guardando = false
      }else{
        let stringImage = "";
        let index = 0;
        var user = auth.getUserLogged();
        user = JSON.parse(user);

        var img = ""
        if(this.subeImg){
          img = "imgRespuesta." + this.fileGrabarExt[this.fileGrabarExt.length-1]
        }else{
          img = ""
        }

        this.comentario = {
          fecha: new Date(Date.now()).toISOString(),
          DescripcionReclamo: this.descripcionComentario,
          Idusuario: user.id,
          idReclamo: this.idreclamo,
          ImagenUrl: img,
        };
  
        axios
          .post(this.$hostname + "/Api/ReclamosSolucion/", this.comentario, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            this.descripcionComentario = "";
            if(this.comentario.ImagenUrl != ""){
              axios
                .post(
                  this.$hostname +
                    "/Api/Reclamos/uploadImgComent/",
                    {
                      "id": idReclamo,
                      "idDetalle": result.data.data.numcodigo,
                      "nombreArchivo": this.comentario.ImagenUrl,
                      "base64": this.base64Aux
                    },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + user.token,
                    },
                  }
                )
                .then((result) => {
                  //console.log('envia img')
                });
            }
            this.cargarDetalle(this.idreclamo);
          })
          .catch((err) => {
            console.log(err)
            this.$alert(
              "Ocurrió un error durante el proceso!",
              "Error",
              "error"
            ).then({});
          });
      }
    },
    cargarUsuarios(result) {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(
          this.$hostname +
            "/Api/UsuariosPorProceso/GetUsuariosPorProcesoSp/" +
            result.descripcionProceso,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((res) => {
          this.usuariosProceso = res.data;
        })
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    },
    cargarDetalle(id) {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      this.idreclamo = id;
      axios
        .get(
          this.$hostname + "/Api/reclamosSolucion/GetReclamosSolucionSp/" + id,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((result) => {
          this.descripcionComentario = ""
          this.detalleReclamo = result.data;
          this.guardando = false
          //console.log(result.data)
          //console.log("idCaso: " + id)
          //console.log("idDetalle: " + this.detalleReclamo.numcodigo)
          this.detalleReclamo.forEach((x)=>{
            if(x.imagenUrl !== ""){
              const obj = {
                id: id,
                idDetalle: x.numcodigo,
                nombreArchivo: x.imagenUrl,
                base64: "N/A"
              }
              //console.log(obj)
              this.cargarImagenes(obj)
            }
          })
        })
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    },
    cargarImagenes(obj) {
      this.openModalCargando()
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .post(
          this.$hostname + "/Api/Reclamos/GetImgCaso/", obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((result) => {
          //console.log(result)
          if(result.data.base64 != undefined){
            var encoded = result.data.base64
            var tipo = encoded.substring(0, 1)
            var base64decoded = null;
            var ext = "";
            if (typeof encoded !== 'string') {
              //console.log(base64decoded);
            }else{
              switch (tipo) {
                case "/":
                  ext = "jpeg";
                  break;
                case "i":
                  ext = "png";
                  break;
                case "R":
                  ext = "gif";
                  break;
                case "U":
                  ext = "webp";
                  break;
                case "J":
                  ext = "pdf";
                  break;
              }
              //console.log(ext)
              base64decoded = "data:image/" + ext + ";base64," + encoded

              var item, href;
              if(result.data.idDetalle == 0){
                item = document.getElementById("img_" + result.data.id)
                href = document.getElementById("href_" + result.data.id)
              }else{
                item = document.getElementById("img_" + result.data.idDetalle)
                href = document.getElementById("href_" + result.data.idDetalle)
              }

              item.setAttribute("src",base64decoded)
              href.setAttribute("href",base64decoded)

              //console.log(this.caso.imagenBase64);
              //style="background-image: url(base64decoded)"
              this.closeModalCargando()
            }
          }else{
            //console.log(result.data.base64)
            //this.$alert(
            //  "Ocurrió un error durante el proceso!",
            //  "Error",
            //  "error"
            //).then({});
            this.closeModalCargando()
          }
        })
        .catch((err) => {
          console.log(err)
          this.closeModalCargando()
          //this.$alert(
          //  "Ocurrió un error durante el proceso!",
          //  "Error",
          //  "error"
          //).then({});
        });
    },
    reasignarPorArea() {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(
          this.$hostname +
            "/Api/Reclamos/UpdateReclamoSp/" +
            this.reasignarPorAreaV +
            "/" +
            this.idTipoReclamo +
            "/" +
            this.idreclamo,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((result) => {
          this.$alert(
            "Se reasignó el caso correctamente!",
            "Éxito",
            "success"
          ).then({});
          //this.cargarReclamos();
          this.$router.push("/Casos/MisCasos");
        })
        .catch((err) => {
          console.log(err)
          //console.log(err.response.data);
          //console.log(err.response.status);
          //console.log(err.response.headers);
          //console.log(err.request);
          //console.log(err.message);
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    },
    reasignar() {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(
          this.$hostname +
            "/Api/Reclamos/UpdateReclamoSp/" +
            this.usuarioAsignado +
            "/" +
            this.idTipoReclamo +
            "/" +
            this.idreclamo,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((result) => {
          this.$alert(
            "Se reasignó el caso correctamente!",
            "Éxito",
            "success"
          ).then(() =>  this.$router.push("/Casos/MisCasos"));

          //this.cargarReclamos();
        })
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    },
  },

  created() {
    this.openModalCargando()
    this.openNavCasos()
    try {
      this.idreclamo = this.$route.params.id;
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.display = "block";
        this.usuario = user.usuario;
        this.loadNavbar(user);
        axios
          .get(
            this.$hostname + "/Api/Reclamos/GetReclamosSp/" + this.idreclamo,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.token,
              },
            }
          )
          .then((result) => {
            this.caso = result.data[0];
            this.caso.imagenBase64 = ""
            //console.log(this.caso)
            this.emailUsuCreacion = this.caso.emailUsuCreacion;
            // console.log(this.emailUsuCreacion);
            this.idTipoReclamo = this.caso.idTipoReclamo;
            this.checked =
              result.data[0].descripcionEstadoReclamos == "CERRADO"
                ? true
                : false;
            this.cargarUsuarios(result.data[0]);
            this.cargarDetalle(result.data[0].numcodigo);
            //console.log(result.data[0].imagenUrl)
            if(result.data[0].imagenUrl !== ""){
              const obj = {
                id: result.data[0].numcodigo,
                idDetalle: 0,
                nombreArchivo: result.data[0].imagenUrl,
                base64: "N/A"
              }
              //console.log(obj)
              this.cargarImagenes(obj)
            }
            this.closeModalCargando()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    this.$alert(
      "Por favor vuelve a ingresar a la plataforma",
      "Expiro la Sesion!",
      "error"
    );
    this.$router.push("/");
    }

    var user = auth.getUserLogged();
    user = JSON.parse(user);
    if (user.token == "" || user.token == undefined) {
      this.$router.push("/");
    } else {
      this.display = "block";
      this.usuario = user.usuario;

      this.loadNavbar(user);
      axios
        .get(this.$hostname + "/Api/procesos/GetProcesosSp?id=" + user.id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((result) => {
          this.tiposDeCaso = result.data;          
        })
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso!",
            "Error",
            "error"
          ).then({});
        });
    }
  },
};
</script>
<style scoped>
/*modal*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: none;
}
.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}
.modalDialog > div {
  max-width: 1350px;
  width: 97%;
  position: relative;
  margin: 10% auto;
  padding: 20px;
  border-radius: 3px;
  background: #fff;
}
.close {
  font-family: Arial, Helvetica, sans-serif;
  background: #f26d7d;
  color: #fff;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 34px;
  height: 34px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  padding-top: 5px;
}
.close:hover {
  background: #fa3f6f;
}

/*modal*/

.requestViewDetails {
  padding: 10px 0px 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.detailBox {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 33.33%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 15px;
  border-left: 1px solid #ddd;
}

.requestViewDetails .itemTitle {
  border-radius: 4px 4px 0 0;
  font-size: 0.688em;
  color: #888;
}
.requestViewDetails .itemCont {
  font-weight: bold;
  color: #555;
  font-size: 0.75em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 4px;
}
.requestViewId {
  font-weight: bold;
  color: #bbb;
  border-radius: 20px 0 0 20px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-right: 0;
  padding: 8px 16px;
  margin: auto;
  white-space: nowrap;
}
.request-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  /* -webkit-box-direction: normal; */
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.messageContainerFirst {
  padding: 20px;
}

.messageContainer {
  padding: 1px 20px;
  position: relative;
}
.messageContainer .userData {
  float: left;
  width: 32px;
}
.messageContainer .userImage {
  background: #fff;
  border: 2px solid #fff;
  -webkit-box-shadow: 2px 2px 1px rgb(0 0 0 / 20%);
  box-shadow: 2px 2px 1px rgb(0 0 0 / 20%);
  border-radius: 32px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.messageContainer .userImage img {
  width: 32px;
  height: 32px;
}
.messageContainerFirst .messageData {
  background: #f2f8ff;
}

.messageContainer .messageData {
  margin-left: 50px;
  position: relative;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  -webkit-box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
  box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
}
.messageContainer .messageArrow {
  position: absolute;
  left: -21px;
  top: 9px;
  border: 10px solid transparent;
  border-right: 10px solid #e5e5e5;
}
.messageContainer .messageArrow:before {
  content: " ";
  position: absolute;
  left: -8px;
  top: -9px;
  border: 9px solid transparent;
  border-right: 9px solid #fff;
}
.messageContainer .messageArrow:after {
  content: " ";
  position: absolute;
  left: -6px;
  top: -8px;
  border: 8px solid transparent;
  border-right: 8px solid #fff;
}
.messageContainer .messageTop {
  color: #444;
  margin: 0 10px;
  height: 30px;
  padding: 0 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
}
.messageContainer .userName {
  font-weight: bold;
  font-size: 0.688em;
  color: #555;
  margin: auto;
}
.userNameIcon {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #369;
}
.messageContainer .messageDate {
  padding-right: 10px;
  margin: auto;
}
.messageDate {
  font-size: 0.688em;
  color: #888;
  padding-left: 16px;
}
.messageContainer .messageNumber {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #888;
  font-weight: bold;
  padding: 0 10px;
  height: 20px;
  line-height: 18px;
  border-radius: 4px;
  font-size: 0.688em;
  background: rgba(255, 255, 255, 0.6);
  margin: auto;
}
.messageContainer .messageCont {
  color: #444;
  font-size: 0.813em;
  line-height: 1.7em;
  padding: 5px 10px;
  word-wrap: break-word;
  overflow: auto;
  max-height: 40vh;
  margin: 5px;
}
.messageContainer .messageAttachments {
  border-top: 1px dotted #ccc;
  margin: 0 10px 4px;
  padding-top: 10px;
}
.filesAttached {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.filesAttached .item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  max-width: 44%;
  margin: 0 1% 6px 0;
  height: 300px;
  border: 1px solid #ddd;
  font-weight: bold;
  border-radius: 4px;
  background: #f4f4f4 no-repeat 6px;
}
.filesAttached .item a {
  height: 24px;
  line-height: 24px;
  padding: 0 10px 0 30px;
  font-size: 0.688em;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filesAttached .item a .filename {
  color: #666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}
.filesAttached .item a:hover .filename {
  color: #444;
}
.filesAttached .item .fileField {
  width: 88%;
  height: 84%;
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: ease-in-out .3s;
}
.filesAttached .item .fileField:hover {
  width: 100%;
  height: 100%;
}
.filesAttached .item .fileField img {
  transition: ease-in-out .3s;
}
.filesAttached .item .fileField:hover img {
  transform: scale(1.3);
}

.filesAttached .item a .extension {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.clear {
  clear: both;
  height: 0;
}
.replyBar {
  padding: 30px 20px;
  background: #fafafa;
  zoom: 1;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.replyBarType {
  margin-bottom: 8px;
  float: right;
}
#reply_textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 20px;
  background: #fff;
  color: #999;
  margin-bottom: 5px;
  font-size: 0.813em;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.uploadPasteContainer {
  background: #f4f4f4;
  border-radius: 4px;
  margin: 10px 0;
}
.uploadPasteContainer .uploadContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
}

.uploadContainer {
  overflow: auto;
  display: none;
  background: #f4f4f4;
  padding: 0 6px;
  border-radius: 4px;
  margin: 10px 0 0;
  height: 230px;
  border-top: 6px solid #f4f4f4;
  border-bottom: 6px solid #f4f4f4;
}
.replyButtons {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
input[type="submit"],
input[type="button"] {
  appearance: none;
}


.buttonBlue, 
.button-blue {
  display: inline-flex;
  background: #1e3a8a;
  font-weight: bold;
  color: #fff;
  font-size: 0.813em;
  padding: 0 20px;
  height: 2.3rem;
  line-height: 35px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  outline: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
}

.buttonGray,
.button-Gray {
  transform: scale(1.5);
}
.buttonGray:focus,
.button-Gray:focus {
  outline: none;
}
i.fas.fa-sync-alt {
  transform: scale(1.3);
}
div[id^='vs'] {
  border-radius: 0.5rem;
  height: 2.25rem;
}
ul[id^='vs']{
  border-radius: 0.5rem;
}
.replyBar #attachFile a {
  float: left;
}
.uploadButton {
  font-size: 0.75em;
  font-weight: bold;
  padding: 0 15px;
  height: 35px;
  line-height: 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  display: block;
  float: left;
  border-radius: 4px;
  background: #fff;
  position: relative;
  margin-right: 10px;
  color: #888;
}
.uploadButton input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 35px;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}
.replyBar #attachFile a {
  float: left;
}

.uploadButton {
  font-size: 0.75em;
  font-weight: bold;
  padding: 0 15px;
  height: 35px;
  line-height: 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  display: block;
  float: left;
  border-radius: 4px;
  background: #fff;
  position: relative;
  margin-right: 10px;
  color: #888;
}
.messageContainerFirst .messageData {
  background: #f2f8ff;
}
.container {
  width: 100%;
  background: #f7fafc;
  /* border: 0.5px solid #a3a8b1; */
  border-radius: 10px;
  top: 40%;
  left: 0%;
  transform: translate(0%, 0%);
  display: flex;
  position: relative;
  margin: 0;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.imgsPreview .imageHolder {
  height: 95px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.beforeUpload .icon {
  width: 35px !important;
  margin: auto;
  display: block;
}
.messageContainer .messageTop {
  color: #444;
  margin: 0 10px;
  height: 30px;
  padding: 0 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-around;
  -ms-flex-align: center;
}
.messageContainer:before {
  content: "";
  height: 0;
  display: block;
  background: #e5e5e5;
}
.messageContainer .userPosition {
  font-size: 0.688em;
  color: #888;
  margin: auto auto auto 10px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  font-weight: bold;
}
.modalImg {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#ImgFull {
  position: relative;
  height: 80%;
}
</style>
